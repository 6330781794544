/* .footer-media {
  justify-content: start;
}

.footer-payment {
  justify-content: end;
} */

.image-logo {
  width: 300px;
}

@media(max-width: 899px) {
  /* .footer-media {
    justify-content: center;
  }

  .footer-payment {
    justify-content: center;
  } */

  .image-logo {
    width: 200px
  }
}