:root {
  --carousel-height: 500px;
  --carousel-ratio: 5 / 3;
}

@media (max-width: 700px) {
  :root {
    --carousel-height: 300px
  }
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%; /* Adjust as needed */
  /* height: var(--carousel-height); Adjust as needed */
  overflow: hidden;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, .2);
  border-radius: 15px;
  aspect-ratio: var(--carousel-ratio);
  background-color: #fff
}

.carousel-image-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  aspect-ratio: var(--carousel-ratio);
}

.carousel-track-wrapper {
  width: 100%;
  aspect-ratio: var(--carousel-ratio);
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-info {
  position: absolute;
  z-index: 3;
  width: 100%;
  aspect-ratio: var(--carousel-ratio);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: end;

  color: white;
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,0.6) 0%, rgba(0,0,0,0) 100%);
}

.carousel-image-container {
  width: 100%;
  flex-shrink: 0;
}

.carousel-image-cover {
  width: 100%;
  aspect-ratio: var(--carousel-ratio);
  position: absolute;
  z-index: 2;
}

.carousel-image {
  width: 100%;
  aspect-ratio: var(--carousel-ratio);
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.carousel-button {
  height: 100%;
  width: 50px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  z-index: 3;
  transition: 0.1s all ease-in;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, .1);
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
}

.carousel-button:hover {
  color: #555;
}

.carousel-button:nth-of-type(1) {
  left: 0px;
}

.carousel-button:nth-of-type(2) {
  right: 0px;
}

.carousel.text {
  border-radius: 0px;
  background-color: #000;
  color: #fff;
  aspect-ratio: unset;
}

.carousel.text .carousel-track-wrapper {
  aspect-ratio: unset;
}

.carousel.text .carousel-track {
  transition: none;
}

.carousel-text-container {
  padding: 20px 0;
  text-align: center;
  width: 100%;
  flex-shrink: 0;
  align-self: center;
}