.custom-multiselect:hover {
    border: 1px solid black;
    border-radius: 5px;
}

.custom-multiselect {
    height: 56px;
}

.custom-multiselect .option:hover {
    background-color: black;
    color: white;
}

.custom-multiselect .option.selected {
    background-color: black;
    color: white;
}