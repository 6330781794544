body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, rgba(142,142,142,1) 0%, rgba(227,227,227,1) 31%, rgba(200,200,200,1) 69%, rgba(255,255,255,1) 100%);
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  padding: 14px
}

.image-overlay {
  position: absolute;
  z-index: 4;
  background-image: url("./assets/imageOverlay.png");
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 80%;
}

.image-overlay.start {
  transform: scaleX(-1);
  right: unset;
  left: 0;
}