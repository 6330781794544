.item-card {
  background-color: transparent;
  transition: .1s all ease-in-out;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);
}

.item-card:hover {
  background-color: rgba(50, 50, 50, .05);
}